h3{
    margin: 0.3rem;
}

.background{
    background-color: none;
    font-size: 15rem;
    font-weight: 900;
    position: fixed;
    width: 100vw;
    height: 90vh;
    display: grid;
    place-items: center;
}



.loading-board{
    height:100vh;
    width:100vw;
    background: linear-gradient(to bottom,rgb(68, 68, 87), rgb(39, 39, 40));
    opacity: 0.9;
    display: grid;
    place-items: center;
}
.loader-inner1{
    text-align: center;
    font-size: 2rem;
    font-weight: 300;
}
.loader-inner1 .header{
    text-align: center;
    font-size: 2rem    
}
.loader-inner2{
    display: grid;
    place-items: center;
    
}
.cellBoard{
    background: linear-gradient(to top, rgba(34,34,34, 0.97), rgba(34,34,51, 0.9)), url("../../assets/bg.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    height: 100%;
    min-height: 100%;
    position: absolute;
    display: grid; 
    grid-template-columns: repeat( auto-fit, minmax(2rem, 1fr) );
    overflow: hidden;
}

.innerCells{
    height: 100%;
    width: 100%;
}

.topLeft-item{
    width: 5rem;
    height: 5rem;
    position: absolute;
    top: 0;
    left: 0
}

.token1, .token2, .token3, .token4{
    width: 9%;
    height: auto;
    position: absolute;
    cursor: pointer;
    z-index: 1
}

.token2, .token4{
    margin-right: -2.5rem
}

.token-pic-1, .token-pic-2, .token-pic-3, .token-pic-4{
    width: 3rem;
    height: auto;
    left:2.5rem;
   
}

.newClass{
    right: 0 
}
.tokenSpace{
    margin-left: 2.5rem;
}
.defaultClass1{
    left: 4rem;
    top: 2rem;
}
.defaultClass2{
    right: 0;
    top: 2rem;
}
.defaultClass3{
    bottom: 0;
    left: 4rem;
    bottom: 2rem;
}
.defaultClass4{
    bottom: 0;
    right: 0;
    bottom: 2rem;
    
}
.board-options1{
    position: absolute;
    z-index:-10;
}
.board-options2{
    position: absolute;
    transform:translate(50vw)
}
.board-options3{
    position: absolute;
    transform:translate(-50vw)
}
.board-options4{
    position: absolute;
    transform:translate(50vw)
}

.select-option-AB{
    width: 10rem;
    padding: 0.5rem;
    box-sizing: border-box;
    box-shadow: 1px 1px 2px black;
    display: grid;
    place-items: center;
    position: absolute;
    transform: translate(-4rem, -4rem);
    background-color: rgba(42, 57, 57, 0.9);
    box-shadow: 0 2px 3px 0 rgba( 31, 38, 45, 0.37 );
    backdrop-filter: blur( 1px );
    -webkit-backdrop-filter: blur( 1px );
    border-radius: 10px;
    border: 1px solid rgba( 255, 255, 255, 0.18 );
}

.select-option-CD{
    width: 10rem;
    padding: 0.5rem;
    box-sizing: border-box;
    box-shadow: 1px 1px 2px black;
    display: grid;
    place-items: center;
    position: absolute;
    transform: translate(-4rem, -12rem);
    background-color: rgba(42, 57, 57, 0.9);
    box-shadow: 0 2px 3px 0 rgba( 31, 38, 45, 0.37 );
    backdrop-filter: blur( 1px );
    -webkit-backdrop-filter: blur( 1px );
    border-radius: 10px;
    border: 1px solid rgba( 255, 255, 255, 0.18 );
}


.alt-move{
    transform: translate(-2.5rem, -10rem);
}

.move-btn{
    background-color: rgba(0, 0, 255, 0.5);
    color: #eee;
    display: block;
    width: 4rem;
    cursor: pointer;
    margin: 0.1rem 0;
    padding: 0.5rem 0rem;
    border: none;
    border-radius: 0.3rem;
}
.cancel-btn{
    background-color: rgba(150, 42, 42, 0.8);
    color: #eee;
    display: block;
    width: 4rem;
    cursor: pointer;
    margin: 0.1rem 0;
    padding: 0.5rem 0rem;
    border: none;
    border-radius: 0.3rem;
}
.drop-btn{
    background-color: green;
    color: #eee;
    display: block;
    width: 4rem;
    cursor: pointer;
    border: none;
    border-radius: 0.3rem;
    margin-left: -1rem;
    padding: 0.5rem 0rem;
}

.move-btn:hover, .cancel-btn:hover, .drop-btn:hover{
    box-shadow: 0px 2px 2px #111;
    color: #fff
}
/* sidebar */
.menu-btn{
    height: 2rem;
    position: fixed;
    top: 0;
    right: 0;
    background-color: rgba(42, 57, 57, 0.7);
    background: none;
    display: grid;
    place-items: center;
    padding: 0.5rem;
    z-index: 10;
    cursor: pointer
    
}
.menu-icon1{
  font-size: 1.5rem;
  border: solid 2px rgb(2, 171, 92);
  color: rgb(2, 171, 92);
  border-radius: 0.25rem;
   box-shadow:
  inset 20px 0 80px #088,   
  inset -20px 0 80px #0ff,  
  inset 20px 0 300px #f0f,  
}
.menu-icon2{
    font-size: 1.5rem;
  border: solid 2px #f37;
  color: #f37;
  border-radius: 0.25rem;
  /* box-shadow:      
  inset 20px 0 80px #f37,   
  inset -20px 0 80px #0ff,  
  inset 20px 0 300px #f37,   */
        
}
.menu-btn:hover .menu-icon1{
    transform: scale(1.1);
    transition: all 0.1s
}
.menu-btn:hover .menu-icon2{
    transform: scale(1.1);
    transition: all 0.1s
}
.toggle-btn{
    height: 2rem;
    position: fixed;
    top: 3rem;
    right: 0rem;
    background-color: rgba(42, 57, 57, 0.7);
    background: none;
    display: grid;
    place-items: center;
    padding: 0.5rem;
    z-index: 10;
    cursor: pointer
}
.toggle-icon1{
    /* font-size: 1.5rem;
    border: solid 2px #f37;
    border-radius: 0.25rem;
    box-shadow:      
    inset 20px 0 80px #f37,   
    inset -20px 0 80px #0ff,  
    inset 20px 0 300px #f37,  
    inset -20px 0 300px #0ff, 
    0 0 50px #fff,            
    -10px 0 80px #f37,        
    10px 0 80px #0ff   */
    font-size: 1.5rem;
    /* border: solid 2px rgb(29, 34, 34); */
    border: solid 2px #088;
    border-radius: 0.25rem;
    box-shadow:
    inset 20px 0 80px #088,   
    inset -20px 0 80px #0ff,  
    inset 20px 0 300px #f0f,  
    inset -20px 0 300px #0ff, 
    0 0 50px #fff,            
    -10px 0 80px #f0f,        
    10px 0 80px #0ff 
}
.toggle-icon2{
    font-size: 1.5rem;
    border: solid 2px #088;
    border-radius: 0.25rem;
    /* box-shadow:
    inset 20px 0 80px #088,   
    inset -20px 0 80px #0ff,  
    inset 20px 0 300px #f0f,  
    inset -20px 0 300px #0ff, 
    0 0 50px #fff,            
    -10px 0 80px #f0f,        
    10px 0 80px #0ff    */
}
.toggle-btn:hover .toggle-icon1{
    transform: scale(1.1);
    transition: all 0.2s
}
.toggle-btn:hover .toggle-icon2{
    transform: scale(1.1);
    transition: all 0.2s
}
.sideboard{
    width: 15rem;
    position: absolute;
    z-index: 2;
    right: 0;
    font-size: 0.9rem;
    font-weight: 100;
    background-color: rgba(42, 57, 57, 0.7);
    padding: 1rem;
    padding-bottom: 1rem;
    box-shadow: 0 8px 32px 0 rgba( 31, 38, 135, 0.37 );
    backdrop-filter: blur( 4px );
    -webkit-backdrop-filter: blur( 4px );
    border-radius: 10px;
    border: 1px solid rgba( 255, 255, 255, 0.18 );
}
.toggle-btn{
    font-size: 1.3rem;
    cursor: pointer;
    z-index: 1;
}
.sideboard-heading{
    font-size: 1rem;
    font-weight: 700;
    margin-top: 0rem;
    margin-bottom: 1rem;
    left: 0rem;
}
.menu-btn-box{
    background: rgba(0,0,0, 0.5);
    height:2rem; 
    width:2rem;
    display: grid; 
    place-items: center;
    border-radius: 0.35rem;
    
}
.credit{
    font-size: 0.9rem;
    /* background-color: red; */
}
.credit a{
    display: inline-block;
    color:#fff;
    text-decoration: none;
}

.toggle-regions{
    width: calc(100% - 2rem);
    color: #fff;
    cursor: pointer;
    position: relative;
    margin:0 auto;
    font-size: 1rem;
    background-color: rgba(42, 57, 57, 0.7);
    padding: 0.5rem;
    box-shadow: 0 8px 32px 0 rgba( 31, 38, 135, 0.37 );
    backdrop-filter: blur( 4px );
    -webkit-backdrop-filter: blur( 4px );
    border-radius: 4px;
    border: 1px solid rgba( 255, 255, 255, 0.18 );
}

.house{
    width: 4rem; 
    height: 4rem;
    position:absolute;
    

}

/* footer */
.footer{
    width: 100%;
    height: 0.7rem;
    background: linear-gradient(to top, rgb(34,34,34), rgb(34,34,51));
    position: fixed;
    bottom: 0;
    z-index: 2;
}

@media screen and (max-width: 1100px){
    .token2, .token4{
        margin-right: 1rem
    }
}
@media screen and (max-width: 767px){
    .token2, .token4{
        margin-right: 2rem
    }
}

@media screen and (max-width: 600px){
    .token2, .token4{
        margin-right: 2.5rem
    }
}

@media screen and (max-width: 400px){
    .token2, .token4{
        margin-right: 3rem
    }
}