body{
  margin: 0;
  background-color: #aaa;
  color: #eee;
}

.main{
  background-color: none;
  margin: 0;
  padding: 0;
  height: calc(100vh - 1.6rem);
  width: 100%;
}